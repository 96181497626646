@font-face {
  font-family: "poppins";
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "poppins-bold";
  src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

* {
  font-family: "poppins", sans-serif;
}

.inter {
  font-family: "poppins", sans-serif !important;
}

.poppins-bold {
  font-family: "poppins-bold", sans-serif !important;
  font-weight: "600";
}

.sidenav---sidenav---_2tBP {
  background: #2264ab !important;
  display: block;
  height: 100vh;
  position: fixed !important;
}

/* .form-group>input {
  border: 1px solid #ABBCCC !important;
  border-radius: 0px !important;
}

.form-group>textarea {
  border: 1px solid #ABBCCC !important;
  border-radius: 0px !important;
} */

.navbar {
  background: white !important;
}

#root_div {
  position: relative;
  background: #f5f5f5;
  box-sizing: border-box;
  overflow: hidden;
}

#root_body {
  padding: 20px;
}

.sidenav---icon-bar---u1f02 {
  background: #fff !important;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover>.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-:hover>.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T .sidenav---navicon---3gCRo,
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u>.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: #fafafa !important;
}

.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: #e9e9e9 !important;
}

/* 
.dropdown-title::after {
  display: none !important;
} */

#navbar-light-example .dropdown-toggle::after {
  display: none !important;
}

#party_master ul button {
  border-radius: 0px !important;
  padding: 18px;
}

#party_master .nav-link {
  color: black !important;
  font-size: 21px;
}

#party_master .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: white !important;
  background-color: #344454 !important;
}

#party_master .nav-pills {
  border-bottom: 2px solid #f5f5f5;
}

.btn-pink {
  background: #ff134e !important;
  color: white !important;
  border-color: #ff134e;
}

.btn-pink:hover {
  background: #e40c42 !important;
  color: white !important;
  border-color: #e40c42;
}

.btn-pink:focus {
  box-shadow: 49, 132, 253;
}

.btn-pink:active {
  color: white !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #e40c42;
  background: #e40c42;
}

.btn-pink:disabled {
  color: white !important;
  border-color: #ff134e;
  background: #ff134e;
}

.btn-grey {
  background: #344454 !important;
  color: white !important;
  border-color: #344454;
}

.btn-grey:hover {
  background: #2f3e4d !important;
  color: white !important;
  border-color: #2f3e4d;
}

.btn-grey:focus {
  box-shadow: 49, 132, 253;
}

.btn-grey:active {
  color: white !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #2f3e4d;
  background: #2f3e4d;
}

.btn-grey:disabled {
  color: white !important;
  border-color: #344454;
  background: #344454;
}

.btn-yellow {
  background: #eeae2d !important;
  color: white !important;
  border-color: #eeae2d;
}

.btn-yellow:hover {
  background: #dfa32c !important;
  color: white !important;
  border-color: #dfa32c;
}

.btn-yellow:focus {
  box-shadow: 49, 132, 253;
}

.btn-yellow:active {
  color: white !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #dfa32c;
  background: #dfa32c;
}

.btn-yellow:disabled {
  color: white !important;
  border-color: #eeae2d;
  background: #eeae2d;
}

.btn-primary-blue {
  background: #2264ab !important;
  color: white !important;
  border-color: #2264ab;
}

.btn-primary-blue:hover {
  background: #2264ab !important;
  color: white !important;
  border-color: #2264ab;
}

.btn-primary-blue:focus {
  box-shadow: 49, 132, 253;
}

.btn-primary-blue:active {
  color: white !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #2264ab;
  background: #2264ab;
}

.btn-primary-blue:disabled {
  color: white !important;
  border-color: #2264ab;
  background: #2264ab;
}

.bg-primary-blue {
  background: #2264ab !important;
  color: white !important;
}

.bg-primary-blue:hover {
  background: #2264ab !important;
  color: white !important;
}

.cursor-pointer {
  cursor: pointer;
}

.forget_pass:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* @font-face {
  font-family: "poppins";
  src: url("../../assets/font/Poppins/Poppins-Regular.ttf") format("truetype");
} */

* {
  font-family: "poppins", sans-serif !important;
}

.poppins {
  font-family: "poppins", sans-serif !important;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-primary-blue {
  background: #2264ab !important;
  color: white;
}

.btn-primary-blue {
  color: #fff;
  background-color: #2264ab;
  border-color: #2264ab;
}

.btn-primary-blue:hover {
  color: #fff;
  background-color: #155497;
  border-color: #155497;
}

.card_title {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.card_title svg {
  vertical-align: middle;
}

.page-wrapper .page-body-wrapper .page-sidebar {
  background: #2264ab !important;
}

.contact-divider {
  position: relative;
  clear: both;
  height: 0;
  margin: 30px 0;
  border-top: 1px solid #eff0f1;
}

.contact-divider::before {
  position: absolute;
  top: -11px;
  left: 40%;
  margin-left: -10px;
  padding: 0 5px;
  color: #1b252a;
  background: #fff;
  content: "Contact Us";
}

.Toastify__toast--success {
  background: #f1f5f2 !important;
}

.Toastify__toast--error {
  background: #f1f5f2 !important;
}

.cursor_pointer {
  cursor: pointer;
}

.footer {
  background-color: white !important;
}

.forget_pass:hover {
  text-decoration: underline;
  cursor: pointer;
}